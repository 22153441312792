@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}
.mapboxgl-ctrl-bottom-right {
  display: none !important;
}

.mapboxgl-ctrl-top-right {
  top: 60px !important;
  right: 15px !important;
  position: absolute;
  pointer-events: none;
  z-index: 2;
}

mark {
  padding: 0;
}

div.bubbleTooltip {	
  position: absolute;			
  border-radius: 3px;
  padding: 7px 10px;
  font-size: 12px;
  background: white;
  -webkit-box-shadow: 0 2px 4px rgba(0,0,0,0.5);
  box-shadow: 0 2px 4px rgba(0,0,0,0.5);
  /*color: #fff;*/
  text-align: left;
  white-space: nowrap;		
  z-index: 1001;
}
.tooltip {
  position: fixed;
  border-radius: 8px;
  background-color: white;
  border: 1px grey solid;
  text-align: left;
  padding: 12px;
  box-shadow: 0px 50px 100px 0px #4db3e626;
  width: 25vw;
  opacity: 0.95;
  /* font-family: Rubik; */
}

.node-type {
  font-size: 12px;
  line-height: 1.5rem;
  text-transform: uppercase;
  color: grey;
}
.title {
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-content: center;
}

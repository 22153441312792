.loader-enter {
  opacity: 1;
}

.loader-enter.loader-enter-active {
  opacity: 1;
}

.loader-enter-done {
  opacity: 1;
}

.loader-exit {
  opacity: 1;
}

.loader-exit.loader-exit-active {
  opacity: 0;
  transition: opacity 0.1s ease-out;
}

.loader-exit-done {
  opacity: 0;
}
